import http from '@/utils/services/http'

export const registerCall = (data) => {
  let url = '/api/call/register'
  let method = http.post
  if (data.id) {
    method = http.patch
  }
  return method(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const monitor = () => {
  let url = '/api/call/monitor'
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
