<template>
  <div class="callcenter">
    <div class="callcenter-container">
      <div class="caller-list">
        <div class="item" v-for="call in calls" :key="call.id" :class="{start: call.start}">
          <div><img src="@/assets/images/callcenter-icon.png" width="80"></div>
          <div class="call-data">
            <div class="number">{{call.number}}</div>
            <div class="person">
              <div class="sem-chamador" v-if="!call.extra || !call.extra.person || !call.extra.person.length">
                Chamador não encontrado
              </div>
              <div class="chamador unico-chamador" v-else-if="call.extra.person.length === 1">
                {{call.extra.person[0].name}}
              </div>
              <div class="chamador multi-chamador" v-else>
                <div class="multi-label">Mais de uma pessoa encontrada com este número:</div>
                <div v-for="chamador in call.extra.person" :key="'call-' + call.id + '-chamador-' + chamador.id">
                  {{chamador.name}}
                </div>
                <div class="multi-select-person">
                  <u-select placeholder="Selecione o chamador" color="white" inverted-light hide-underline v-model="call.person" :options="mapPersonOptions(call)" />
                </div>
              </div>
            </div>
            <div class="options">
              <div class="full-width">
                <u-input placeholder="Cadastre o chamador" class="m-b bg-white text-black" hide-underline v-model="call.newPerson" />
                <u-btn @click="atender(call)" label="Iniciar atendimento" class="full-width" color="black" no-caps />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center full-width full-height col-grow-1" v-if="!calls || !calls.length">
      Nenhuma ligação até o momento
    </div>
  </div>
</template>

<script>
import DefaultMixin from './mixin'
import {monitor, registerCall} from '@/domain/caller/services'
import {USelect, UInput} from 'uloc-vue'
// import Vue from 'vue'

export default {
  name: 'CallcenterMain',
  components: {USelect, UInput},
  mixins: [DefaultMixin],
  provide: function () {
    return {
      callcenter: this
    }
  },
  data () {
    return {
      calls: []
    }
  },
  computed: {
  },
  meta: {
    title: 'Callcenter',
    name: 'Callcenter'
  },
  mounted () {
    this.$audio = typeof Audio !== "undefined" ? new Audio('https://static.suporteleiloes.com/global/audios/vendido.mp3') : null
    window.setTimeout(() => {
      this.erplayout.erpheader.setMenuModuleToggle(true)
    }, 100)
    this.startMonitoring()
  },
  beforeDestroy () {
    this.erplayout.erpheader.setMenuModuleToggle(false)
    this.$uloc.fullscreen.exit()
  },
  methods: {
    load () {
      monitor()
          .then(response => {
            console.log(response)
            if (response.data && response.data.length) {
              const ids = []
              response.data.map(call => {
                call.start = false
                ids.push(call.id)
                let exists = this.calls.find(c => c.id === call.id)
                if (exists && exists.status !== call.status) {
                  this.calls.splice(this.calls.indexOf(exists), 1)
                  this.calls.push(call)
                  this.$audio && this.$audio.play()
                } else if (!exists) {
                  this.calls.push(call)
                  this.$audio && this.$audio.play()
                }
              })
              this.calls.map(call => {
                if (!ids.includes(call.id)) {
                  this.calls.splice(this.calls.indexOf(call), 1)
                }
              })
            } else {
              this.calls = []
            }
          })
    },
    startMonitoring () {
      this.$interval = setInterval(() => {
        this.load()
      }, 2000)
      this.load()
    },
    mapPersonOptions (call) {
      return call.extra.person.map(p => {
        return {label: p.name, value: p}
      })
    },
    atender (call) {
      const callback = (c) => {
        this.$uloc.caller.call(c.number, c.person, null, {type: c.type, mode: null, id: c.id}, null, this.save, this.$router)
      }
      let post = {
        person: call.person && call.person.id ? call.person.id : call.person,
        id: call.id,
        type: 0,
        data: call
      }
      if (call.newPerson) {
        if (!call.newPerson.length) {
          this.$uloc.dialog({title: 'Pessoa não identificada'})
          return
        }
        post = {
          person: {
            isNew: true,
            name: call.newPerson
          },
          id: call.id,
          type: 0,
          data: call
        }
      }
      if (!post.person) {
        this.$uloc.dialog({title: 'Pessoa não identificada'})
        return
      }
      call.start = true
      this.$uloc.notify({color: 'info', position: 'bottom-left', message: 'Criando pessoa no sistema...'})
      registerCall(post)
          .then(response => {
            console.log(response)
            this.$uloc.notify({color: 'positive', position: 'bottom-left', message: 'Ligação registrada'})
            callback(response.data)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    save (data) {
      console.log(data)
      const post = {
        person: data.person.id,
        type: 0,
        mode: null,
        data: {...data, type: 0}
      }
      if (data.id) {
        post.id = data.id
      }
      this.$uloc.notify({color: 'info', position: 'bottom-left', message: 'Registrando ligação...'})
      registerCall(post)
          .then(response => {
            console.log(response)
            this.$uloc.notify({color: 'positive', position: 'bottom-left', message: 'Ligação registrada'})
          })
          .catch(error => {
            this.alertApiError(error)
          })
      console.log('Save... ', data)
    }
  }
}
</script>

<style lang="stylus" src="./assets/default.styl" />
